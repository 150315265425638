.tr td{
    border: 1px solid #989999;
}

.tr {
  background-color: #f1f0f0;
  line-height: 24px;
}

.tr:nth-child(2n+1) {
  background-color: #ffffff;
}