.container {
  border: 1px solid #bababa;
  width: 53px;
  height: 24px;
  display: flex;
}

.input {
  width: 24px;
  border-width: 0;
  line-height: 100%;
  height: 100%;
  background: transparent;
  padding: 0 6px;
  outline: none;
}

.buttonTop,
.buttonDown {
  width: 100%;
  height: 50%;
  background: #421719;
  cursor: pointer;
  position: relative;

  &:hover {
    background: #6f272a;
  }
}

.buttonTop:before {
  content: '^';
  color: #ffffff;
  position: absolute;
  top: -5px;
  left: 7px;
}

.buttonDown:before {
  content: '^';
  color: #ffffff;
  position: absolute;
  left: 7px;
  transform: rotateX(180deg);
  bottom: -5px;
}

.buttons {
  width: 100%;
}
