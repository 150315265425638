.InputCount_container__1HNyC {
  border: 1px solid #bababa;
  width: 53px;
  height: 24px;
  display: flex; }

.InputCount_input__1q7Px {
  width: 24px;
  border-width: 0;
  line-height: 100%;
  height: 100%;
  background: transparent;
  padding: 0 6px;
  outline: none; }

.InputCount_buttonTop__LRXRd,
.InputCount_buttonDown__8_6Y9 {
  width: 100%;
  height: 50%;
  background: #421719;
  cursor: pointer;
  position: relative; }
  .InputCount_buttonTop__LRXRd:hover,
  .InputCount_buttonDown__8_6Y9:hover {
    background: #6f272a; }

.InputCount_buttonTop__LRXRd:before {
  content: '^';
  color: #ffffff;
  position: absolute;
  top: -5px;
  left: 7px; }

.InputCount_buttonDown__8_6Y9:before {
  content: '^';
  color: #ffffff;
  position: absolute;
  left: 7px;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  bottom: -5px; }

.InputCount_buttons__3GHZI {
  width: 100%; }

.ItemRow_tr__pVK4F td {
  border: 1px solid #989999; }

.ItemRow_tr__pVK4F {
  background-color: #f1f0f0;
  line-height: 24px; }

.ItemRow_tr__pVK4F:nth-child(2n+1) {
  background-color: #ffffff; }

.Table_table__1WwYJ {
  width: 100%;
  font-weight: normal;
  border-color: #989999;
  font-size: 11px;
  box-sizing: border-box;
  font-family: Arial; }

.Table_headRow__1NlTm {
  background-color: #989999;
  padding: 10px 0;
  line-height: 24px;
  font-weight: 400; }

.Table_priceColumn__1Wqlp {
  width: 60px; }

.Table_codeColumn__3su4_ {
  font-weight: bold; }

.Table_table__1WwYJ td {
  padding: 5px 10px; }

.Table_tr__2qgla {
  border: 1px solid #989999; }

.GroupsTable_groupsTableWrapper__1WIdD {
  width: 100%;
  display: flex;
  flex-wrap: wrap; }

.GroupsTable_groupsTable__28vYK {
  width: 100%;
  font-size: 11px;
  font-family: Arial;
  line-height: 24px; }

.GroupsTable_header__18Ga_ {
  color: #000;
  background: #989999; }
  .GroupsTable_header__18Ga_ > th {
    padding: 10px 0px; }

.GroupsTable_body__wS9ed td {
  padding: 5px 10px;
  color: #666666;
  border-color: #989999;
  border-width: 0 1px 1px 0;
  border-style: solid; }

.GroupsTable_body__wS9ed td:first-child {
  border-width: 1px; }

.GroupsTable_open__111cQ {
  font-size: 14px;
  cursor: pointer;
  color: #782a2d;
  text-decoration: underline; }

.GroupsTable_open__111cQ:hover {
  text-decoration: none; }

.GroupsTable_moreButton__2eCsG {
  cursor: pointer;
  position: relative;
  margin-left: 3px;
  margin-top: 5px;
  font-weight: bold; }

.Loader_loader__GE3Ax {
  position: fixed;
  bottom: 40px;
  width: 200px;
  height: 200px;
  left: 50%;
  margin-left: -100px;
  z-index: 10; }

.Filters_title__1O-yD {
  width: 100%; }

.Filters_filterWrapper__17q_I {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px; }

.Filters_button__IrDTp {
  width: 22%;
  border-width: 1px 1px 1px 1px;
  border-color: #666;
  border-style: solid;
  padding: 10px;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  margin-bottom: 1px;
  margin-left: 1px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }

.Filters_active__1ZiAQ {
  background-color: rgba(63, 255, 0, 0.36); }

.MainPage_wrapper__3e4xM {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%; }

.wrapper-search-etsp * {
  box-sizing: border-box;
}

.App {
  width: 100%;
}


