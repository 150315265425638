.groupsTableWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.groupsTable {
  width: 100%;
  font-size: 11px;
  font-family: Arial;
  line-height: 24px;
}

.header {
  color: #000;
  background: #989999;

  & > th {
    padding: 10px 0px;
  }
}

.body {
  & td {
    padding: 5px 10px;
    color: #666666;
    border-color: #989999;
    border-width: 0 1px 1px 0;
    border-style: solid;
  }

  & td:first-child {
    border-width: 1px;
  }
}

.open {
  font-size: 14px;
  cursor: pointer;
  color: #782a2d;
  text-decoration: underline;
}

.open:hover {
  text-decoration: none;
}

.moreButton {
  cursor: pointer;
  position: relative;
  margin-left: 3px;
  margin-top: 5px;
  font-weight: bold;
}