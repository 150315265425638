.table {
  width: 100%;
  font-weight: normal;
  border-color: #989999;
  font-size: 11px;
  box-sizing: border-box;

  font-family: Arial;
}

.headRow {
  background-color: #989999;
  padding: 10px 0;
  line-height: 24px;
  font-weight: 400;
}

.priceColumn {
  width: 60px;
}

.codeColumn {
  font-weight: bold;
}

.table td {
  padding: 5px 10px;
}

.tr {
  border: 1px solid #989999;
}