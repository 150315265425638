.title {
  width: 100%;
}

.filterWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.button {
  width: 22%;
  border-width: 1px 1px 1px 1px;
  border-color: #666;
  border-style: solid;
  padding: 10px;
  text-transform: uppercase;
  font-size: 14px;
  text-align: center;
  margin-bottom: 1px;
  margin-left: 1px;
  user-select: none;
}

.active {
  background-color: rgba(63, 255, 0, 0.36);
}